///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
/// @setup change all for new brand

$tokens: () !default;

// Layout
$max-width: 1024px !default;
$max-width-large: 1200px !default;
$max-width-xlarge: 1920px !default;
$admin-toolbar-height: 61px !default;
$header-height: 40px !default;
$header-height-desktop: 140px !default;
$offer-banner-height: 30px !default;
$site-banner-height: 30px !default;
// Layout - Core
$core-header-height: 42px !default;
$core-header-height-desktop: 82px !default;
// Layout -- common product width variables
$mpp-container-max-width: 1000px !default; // MPP Container max width
$mpp-max-width: 1024px !default; // this is product image width x3 plus margins
$spp-data-max: 1024px !default;
$spp-content-max: $max-width-large !default;

// Max widths for formatters and content containers
// @todo verify widths for 2020 styleguide updates
$container-max-width-landscape: 1400px !default;
// Content containers outer pad vars
// @todo verify widths for 2020 styleguide updates
$container-pad-small: 20px !default;
$container-pad-landscape: 100px !default;

// RTL
$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;

// used to offset anchors, etc.
$header-padding: 11px;
$header-max-width: 1280px;
$header-subnav-color: #9e9a94;
$header-portrait-up: $portrait-up;
$cart-offset: 42px;
$cart-offset-mobile: 87px;

$social-shopping-bar-height: 40px;
$social-shopping-bar-height-large: 45px;

$footer-max-width: $max-width-large;
// Point at which footer changes
$footer-breakpoint: $landscape-up;

$search-max-width: 1280px;

// Images
$img-base-url: '../img/';

// Typography
$font-aveda-sans: 'Aveda Sans', arial, helvetica, sans-serif !default;
$font-meta: 'FFMeta', arial, helvetica, sans-serif !default;
$font-meta-normal: 'FFMeta', arial, helvetica, sans-serif !default;
$primary-font: $font-meta;
$primary-font-rev: $font-meta;
$base-theme-path: '/sites/aveda/themes/aveda_base/' !default;
$netstorage-fonts-path: '/_fonts/aveda/'; // netstorage path
$font-calibri: Calibri, sans-serif !default;
$font-cambria: Cambria !default;
$font-cambria-math: Cambria Math !default;
$font-arial: Arial, sans-serif !default;
$font-arial-narrow: Arial, Narrow !default;
$primary-font-eng: $primary-font;
$font--sans: sans-serif !default;

$base-font-size: 16px;
$base-line-height: 1.375;

// spacing units
// vertical
$spacing: 10px;
$spacing--small: 15px;
$spacing--med: 28px;
$spacing--large: 56px;

// Video player
$video-wrapper-icon-size: 60px;
$video-play-icon-size: 30px;

// Transitions
$transition-time: 0.3s;

// Content Blocks.
$content-block-sitewide-banner-height: 145px !default;
$content-block-sitewide-banner-height-desktop: 90px !default;

// If you have questions about why these aren't classes
// (http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither/)
// It's because that method triggers compatability mode:
// https://github.com/h5bp/html5-boilerplate/issues/1187
$ie8: "html[data-useragent*='MSIE 8.0']";
$ie9: "html[data-useragent*='MSIE 9.0']";
$ie10: "html[data-useragent*='MSIE 10.0']";
$ie11: "html[data-useragent*='MSIE 11.0']";

// Compass variables
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;

// LBO default SCSS variable configuration
// This avoids loading of LBO related CSS to all locales
$lbo-enabled: false !default;

// Redesign 2020
// Gnav
$cr20: false !default;
//Hit area updates.
$hit_area_update: true !default;

//Adyen Integration
$adyen: false !default;
// Styleguide updates
$styles20: false !default;
// /opc updates
$opc_update: false !default;
// /bopis updates
$bopis_update: false !default;
//custom footer hide
$is_custom_footer: true !default;
// TMO UI Update
$tmo_order_tracking_update: false !default;
$tmo-item-flex: false !default;

// Product Full - Misc
$full-screen-carousel-button-size: 50px;
$full-screen-outer-padding: 20px;
$full-screen-inner-padding-top: 20px;
$full-screen-inner-padding-bottom: 50px;
$full-screen-image-height-offset: ($full-screen-outer-padding * 2) + $full-screen-inner-padding-top +
  $full-screen-inner-padding-bottom;

// pw filter
$hide_oldest_pw_filter: false !default;

// Pure privilege certificates styles for offer-code-panel.
$pure_privilege_certificates: false !default;

// Search Redesign 2024.
$search_modernized_design: false !default;

// PDP Redesign 2024.
$pdp_modernized_design: false !default;

// Typography Refresh 2024.
$typography_cr24: false !default;
